<template>
  <div>
    <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">品类编号</span>
        <el-input v-model="searchParams.productCode" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">品类名称</span>
        <el-input v-model="searchParams.name" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row"> </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end" style="margin-bottom: 12px">
      <el-button type="primary" size="small" @click="(isEdit = false), (dialogVisible = true)">新建</el-button>
    </el-row>
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="未录入答案" name="1"></el-tab-pane>
      <el-tab-pane label="已录入答案" name="2"></el-tab-pane>
                                                                                                                                                                                                                  </el-tabs> -->

    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      header-cell-class-name="header-cell-bg"
      header-row-class-name="header-bg"
      @cell-click="onCellClick"
    >
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="productCode" label="品类编号"> </el-table-column>

      <el-table-column prop="name" label="品类名称"> </el-table-column>
      <el-table-column prop="questionType" label="类型">
        <template slot-scope="scope">
          {{ getType(scope.row.resourceType) }}
        </template>
      </el-table-column>
      <el-table-column prop="unitSpecs" label="规格">
        <template slot-scope="scope">
          {{ scope.row.countPerUnit }}
          {{ getunit(scope.row.unitSpecs) }}/
          {{ getMeasurement(scope.row.unitOfMeasurement) }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="unitOfMeasurement" label="单位">
        <template slot-scope="scope">
          {{ getunit(scope.row.unitSpecs) }}
        </template>
      </el-table-column> -->
      <el-table-column prop="descriptions" label="描述" show-overflow-tooltip> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button slot="reference" type="text" size="small" @click="toEdit(scope.row)">编辑</el-button>
          <el-popconfirm title="确认删除?" @confirm="toDelete(scope.row.id)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createStockin :currentItem="currentItem" :dialogVisible.sync="dialogVisible" :isEdit="isEdit" :currentId="currentId" @updateList="getList">
    </createStockin>
  </div>
</template>

<script>
import { listProduction, deleteProduction } from '@/api/api.js';
import createStockin from '../../../components/createStockin.vue';
import dayjs from 'dayjs';
export default {
  components: {
    createStockin
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      expertName: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      questionType: '',
      searchParams: {
        name: '',
        productCode: ''
      },
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '1',

      tableData: [],
      options: [],
      allDic: null,
      questionTypeDic: null,
      currentItem: null
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getDicList();
  },
  methods: {
    toDelete(id) {
      deleteProduction({ id })
        .then((e) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        })
        .catch((error) => {});
    },
    getType(key) {
      return this.questionTypeDic[key];
    },
    getunit(key) {
      return this.allDic['unit.specs'][key];
    },
    getMeasurement(key) {
      return this.allDic['unit.of.measurement'][key];
    },
    toDetail(item) {
      this.currentId = item.id;
      this.isEdit = false;
      this.dialogVisible = true;
    },
    toEdit(item) {
      this.currentItem = item;
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    async getDicList() {
      let res = await this.$store.dispatch('getDicAll');
      this.allDic = res;
      let list = res['agriculture.resource.type'];
      this.questionTypeDic = list;
      let options = [];
      for (const key in list) {
        if (Object.hasOwnProperty.call(list, key)) {
          const e = list[key];
          options.push({ value: Number(key), label: e });
        }
      }
      this.options = options;
    },
    getQuestionType(key) {
      return this.questionTypeDic[key];
    },
    onCellClick(row) {
      // this.$router.push({ name: 'detailService', query: { id: row.id, from: 'metting' } })
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },

    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      listProduction({ pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize, ..._.pickBy({ ...this.searchParams }) })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
