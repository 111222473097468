<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="商品统计" name="first">
        <product :allDic="allDic" :userOpitons="userOpitons" :departmentsOpitons="departmentsOpitons"></product>
      </el-tab-pane>
      <el-tab-pane label="部门统计" name="second">
        <department :allDic="allDic" :userOpitons="userOpitons" :departmentsOpitons="departmentsOpitons"></department>
      </el-tab-pane>
      <el-tab-pane label="出入库统计" name="third"
        ><stock :allDic="allDic" :userOpitons="userOpitons" :departmentsOpitons="departmentsOpitons"
      /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { departmentsList, usersList } from '@/api/api.js';
import department from './department.vue';
import stock from './stock.vue';
import product from './product.vue';
export default {
  components: {
    product,
    department,
    stock
  },
  data() {
    return {
      activeName: 'first',
      allDic: {},
      userOpitons: [],
      departmentsOpitons: []
    };
  },
  created() {},
  mounted() {
    this.getInitData();
  },
  methods: {
    async getInitData() {
      let allDic = await this.$store.dispatch('getDicAll');
      this.allDic = allDic;
      departmentsList().then(res => {
        this.departmentsOpitons = res;
      }).catch(error => {

      });
      usersList().then(res => {
        this.userOpitons = res;
      }).catch(error => {

      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
